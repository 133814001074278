import React, { useState, useEffect } from 'react';
import ossixLogo from 'assets/images/simple-logo.svg';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown, Image, Input, Button } from 'semantic-ui-react';
import { useHistory } from 'react-router';

import findUser from 'redux/actions/contacts/locateWallet';
import { clearFoundUser } from 'redux/actions/contacts/locateWallet';

const TransferFundsToWallet = prop => {
  const {
    currentOption,
    setAmount,
    amount,
    checking,
    setCurrentPage,
    onSubmitWalletTransactionConfirmation,
    targetWallet,
    setTargetWallet,
    setTargetWalletOption,
    location,
  } = prop;

  const dispatch = useDispatch();
  const history = useHistory();

  const [
    selectedAccountNumber,
    setSelectedAccountNumber,
  ] = useState();
  const [enableNextProcess, setEnableNextProcess] = useState(true);
  const [contactList, setContactList] = useState([]);
  const [
    phoneNumberFromContacts,
    setPhoneNumberFromContacts,
  ] = useState();
  const { locateWallet, allContacts } = useSelector(
    ({ contacts }) => contacts,
  );
  const walletFound = locateWallet?.data;

  const onHandleChangeAmount = e => {
    setAmount(e.target.value);
  };

  const onHandleGetAccountNumber = e => {
    setSelectedAccountNumber(e.target.value);
  };

  const onSearchAccountNumber = () => {
    findUser({ WalletNumber: selectedAccountNumber })(dispatch);
  };

  const onHandleChanges = (event, data) => {
    const { value } = data;
    setPhoneNumberFromContacts(value);
  };
  useEffect(() => {
    if (location?.state?.defaultValues) {
      const { state } = location;
      setSelectedAccountNumber(state.defaultValues.contactPID);
      findUser({ WalletNumber: state.defaultValues.contactPID })(
        dispatch,
      );
    }
  }, [location?.state?.defaultValues]);

  useEffect(() => {
    if (walletFound) {
      let newWallet;
      const wallets = walletFound?.filter(wallet => {
        newWallet = {
          CurrencyCode: wallet?.CurrencyCode,
          CountryCode: wallet?.CountryCode,
          PID: wallet?.PID,
          WalletNumber: wallet?.WalletNumber,
        };
      });
      setTargetWallet(newWallet);
    }
  }, [walletFound]);

  useEffect(() => {
    if (phoneNumberFromContacts) {
      const wallet = allContacts?.data?.filter(
        contact => contact?.PhoneNumber === phoneNumberFromContacts,
      );
      setTargetWallet({
        CurrencyCode: wallet[0]?.DefaultWallet?.Currency,
        CountryCode: wallet[0]?.CountryCode,
        PID: wallet[0]?.ContactPID,
        WalletNumber: wallet[0]?.DefaultWallet?.WalletNumber,
      });
    }
  }, [phoneNumberFromContacts]);

  useEffect(() => {
    if (targetWallet) {
      setEnableNextProcess(false);
    }

    if (phoneNumberFromContacts) {
      setSelectedAccountNumber();
    }

    if (selectedAccountNumber) {
      setPhoneNumberFromContacts();
    }
  }, [targetWallet, selectedAccountNumber, phoneNumberFromContacts]);

  useEffect(() => {
    const contacts = allContacts.data?.map(contact => {
      return {
        key: contact?.PhoneNumber,
        text: (
          <div className="dropdown-img-text-container">
            <img src={contact?.PictureURL} className="dropdown-img" />
            <span className="dropdown-img-text">
              {`${contact?.FirstName} ${contact?.LastName}`}
            </span>
          </div>
        ),
        value: `${contact?.PhoneNumber}`,
      };
    });
    setContactList(contacts);
  }, [allContacts?.data]);

  useEffect(() => {
    const targetWallet = allContacts.data?.filter(contact => {});
  }, []);

  return (
    <>
      <div className="card-box">
        <div className="card-box__items-details">
          <Image src={ossixLogo} style={{ width: '40px' }} />
          <div className="card-box__items-left">
            <div className="items_content">
              <span className="btn-title__top">
                {global.translate('CEDAICI Wallet')}
              </span>
              <span className="btn-title__bottom">
                {global.translate('Transfer funds to wallet')}
              </span>
            </div>
          </div>
          <div></div>
        </div>
      </div>

      {!phoneNumberFromContacts ? (
        <div className="card-box__field">
          <span className="card-title">
            {global.translate('Select the recipient')}
          </span>
          <div className="card-box__items-field">
            <div className="card-box__items-field-btn">
              <Input
                className="icon"
                labeled
                value={selectedAccountNumber || ''}
                onChange={onHandleGetAccountNumber}
                placeholder={global.translate(
                  'Wallet number, username or phone number',
                )}
                style={{ border: 'none', width: '90%' }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    onSearchAccountNumber();
                  }
                }}
              />
              {!walletFound ? (
                <Button
                  icon="search"
                  loading={locateWallet?.loading}
                  onClick={onSearchAccountNumber}
                />
              ) : (
                <Button
                  icon="cancel"
                  onClick={() => {
                    clearFoundUser()(dispatch);
                    setTargetWallet(undefined);
                    setSelectedAccountNumber();
                  }}
                />
              )}
            </div>
            <div className="small-separator"></div>
            {phoneNumberFromContacts === undefined &&
              walletFound &&
              walletFound?.map(item => (
                <div className="user-found-box">
                  {item?.PictureURL !== '' ? (
                    <div
                      className="orval-shape"
                      style={{ background: 'transparent' }}
                    >
                      <Image src={item?.PictureURL} />
                    </div>
                  ) : (
                    <div className="orval-shape">
                      <span className="label">
                        {`${item?.FirstName}`.charAt(0)}
                        {`${item?.LastName}`.charAt(0)}
                      </span>
                    </div>
                  )}

                  <div className="items_content">
                    <span className="btn-title__top">
                      {item?.FirstName} {item?.LastName}
                    </span>
                    <span className="btn-title__bottom">
                      {item?.WalletNumber}
                    </span>
                  </div>
                </div>
              ))}
            {!walletFound ? (
              <div className="warning-box">
                <span>
                  {global.translate(
                    'You haven’t selected any contact yet.',
                  )}
                </span>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      {phoneNumberFromContacts === undefined &&
      selectedAccountNumber === undefined ? (
        <div className="separator-box">
          <div className="box-small-separator"></div>
          <span>{global.translate('Or')}</span>
          <div className="box-small-separator"></div>
        </div>
      ) : null}

      {!selectedAccountNumber ? (
        <div className="card-box__items-field">
          <div className="card-box__field">
            <span className="card-title">
              {global.translate('Search for a contact')}
            </span>
            <div className="input-field">
              <Dropdown
                fluid
                width={6}
                selection
                clearable
                options={contactList}
                onChange={onHandleChanges}
                value={selectedAccountNumber}
                placeholder={global.translate('Search for a contact')}
              />
            </div>
          </div>
        </div>
      ) : null}

      <div className="card-box__field">
        <span className="card-title">
          {global.translate('Amount')}
        </span>
        <div className="input-field">
          <Input
            label={{
              basic: true,
              content: currentOption?.CurrencyCode,
            }}
            labelPosition="right"
            value={amount}
            onChange={onHandleChangeAmount || ''}
            name="text"
            placeholder={global.translate('0.0')}
          />
        </div>
      </div>

      <div
        className="bottom-transition__box"
        style={{ marginTop: '-15px' }}
      >
        <Button
          id="btn-back"
          onClick={() => {
            setCurrentPage(0);
            history.replace();
          }}
        >
          {global.translate('Back')}
        </Button>

        <Button
          onClick={() => onSubmitWalletTransactionConfirmation()}
          loading={checking}
          disabled={
            enableNextProcess === true ||
            !targetWallet ||
            amount === '' ||
            amount === 0
              ? true
              : false
          }
          style={{ marginLeft: '10px' }}
        >
          {global.translate('Proceed')}
        </Button>
      </div>
    </>
  );
};

export default TransferFundsToWallet;
