export const GET_ACTIVITY_SECTOR_LIST_START =
  'GET_ACTIVITY_SECTOR_LIST_START';
export const GET_ACTIVITY_SECTOR_LIST_SUCCESS =
  'GET_ACTIVITY_SECTOR_LIST_SUCCESS';
export const GET_ACTIVITY_SECTOR_LIST_FAILURE =
  'GET_ACTIVITY_SECTOR_LIST_FAILURE';
export const GET_ACTIVITY_SECTOR_LIST_END =
  'GET_ACTIVITY_SECTOR_LIST_END';
export const CLEAR_GET_ACTIVITY_SECTOR_LIST =
  'CLEAR_GET_ACTIVITY_SECTOR_LIST';
