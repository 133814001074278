import React, {
  useState,
  useEffect,
  useReducer,
  createContext,
  useCallback,
} from 'react';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import BillsCategories from 'components/nPayBills/billsCategories/index';
import { useDispatch } from 'react-redux';
import getProviders from 'redux/actions/providers/getProviders';
import {
  ACTION_TYPE,
  MNO_GETAWAY_OPERATOR,
  MNO_PROVIDERS_TYPE,
  PAY_BILL_ACTION_TYPE,
} from 'constants/providersTypes';
import getProvidersCountries from 'redux/actions/providers/getProvidersCountries';
import getBillsCategoriesListData from 'redux/actions/payBills/getBillsCategoriesListData';
import gatewayTransferConfirmationData, {
  clearGatewayTransferConfirmation,
} from 'redux/actions/moneyTransfer/gatewayTransferConfirmationData';
import sendFundsToGatewayData, {
  clearSendFundToGatewayStore,
} from 'redux/actions/moneyTransfer/sendFundsToGatewayData';
import verifyGatewayAccount, {
  clearVerifyGateway,
} from 'redux/actions/payBills/verifyGatewayAccount';
import { initialValues } from '../useReducers';
import itemWithFlag, {
  ItemWithFlag,
} from 'components/nPayBills/common/itemWithFlag';
import {
  GATEWAY_INVOICE_POSTPAID,
  GATEWAY_INVOICE_PREPAID,
  GATEWAY_PREPAID_CARD,
} from 'constants/getwayOperationTypes';
import { payBillsReducers } from '../useReducers';
import { PAY_BILLS_ACTIONS_TYPES } from '../actionType';
import { toast } from 'react-toastify';
import useResponse from '../useResponse';
import { SUPPLIERS_PAY_BILLS } from 'constants/general';
import { clearSuppliers } from 'redux/actions/payBills/getSuppliers';
import { clearFailedOperationData } from 'redux/actions/users/operations';
import { clearWalletUpdateData } from 'redux/actions/users/walletUpdate';
import findUser, {
  clearFoundUser,
} from 'redux/actions/contacts/locateWallet';

const UserContext = createContext();

const BillsCategoriesContainer = props => {
  const location = props.location?.state;
  const dispatch = useDispatch();
  const history = useHistory();
  const [getPin, setGetPin] = useState();
  const [nextStep, setNextStep] = useState(false);
  const [PIN, setPIN] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState();
  const [countriesList, setCountriesList] = useState([]);
  const [getSuppliers, setGetSuppliers] = useState([]);
  const [alertMsg, setAlertMsg] = useState(false);
  const [getSelectedCountry, setGetSelectedCountry] = useState();
  const [getSendFundGateway, setGetSendFundGateway] = useState(true);
  const [partnerResponse, setPartnerResponse] = useState();
  const [contactFound, setContactFound] = useState();
  const [selectedContact, setSelectedContact] = useState([]);
  const [tel, setTel] = useState(undefined);
  const [operatorShortName, setOperatorShortName] = useState('');
  const [selectedUserAccount, setSelectedUserAccount] = useState('');
  const [getAmount, setGetAmount] = useState({
    amount: '',
  });
  const [dynamicAmount, setDynamicAmount] = useState({
    amount: '',
  });
  const [inputField, setInputField] = useState({
    data: [],
    Meta: {},
  });
  const [startDate, setStartDate] = useState(new Date());
  const [billsCategoriesList, setBillsCategoriesList] = useState();
  const [confirmTransactionPin, setConfirmTransactionPin] = useState(
    {},
  );
  const [form, setForm] = useState({
    amount: '',
  });
  const [getAmountFromOption, setGetAmountFromOption] = useState({
    amount: '',
    currencyCode: '',
  });
  const [getDropdownInput, setGetDropdownInput] = useState();
  const [
    getDropdownOptionValue,
    setGetDropdownOptionValue,
  ] = useState();
  const [currentWalletOption, setCurrentWalletOption] = useState(
    null,
  );
  const [
    currentProviderOption,
    setCurrentProviderOption,
  ] = useState();
  const [updateOperationStatus, setUpdateOperationStatus] = useState({
    isPending: false,
    failed: false,
    success: false,
  });

  useEffect(() => {
    if (currentProviderOption) {
      setInputField();
      clearVerifyGateway()(dispatch);
    }
  }, [currentProviderOption]);

  const {
    verifiedGatewayAccount,
    walletList,
    currentUser,
    operations,
    walletUpdate,
    providersData,
    providersCountries,
    billsCategories,
    requestFundsToGateway,
    transactionConfirmation,
    locateUser,
  } = useResponse();

  const [defaultValues, setDefaultValues] = useState();
  const getDefaultValues = useCallback(() => {
    setDefaultValues(() => {
      return {
        countryCode: currentUser?.countryCode,
      };
    });
  }, []);

  const displayPopup = ({
    title,
    text,
    icon,
    showConfirmButton,
    showCancelButton,
  }) => {
    Swal.fire({
      title: title,
      text: text,
      icon: icon,
      showConfirmButton: showConfirmButton,
      showCancelButton: showCancelButton,
      allowOutsideClick: true,
    });
  };

  const [payBillsState, dispatchPayBills] = useReducer(
    payBillsReducers,
    initialValues,
  );

  const {
    selectedCountries,
    wallets,
    providers,
    selectedWallet,
    size,
    open,
    getUser,
    openResult2Modal,
  } = payBillsState;

  useEffect(() => {
    if (walletList) {
      dispatchPayBills({
        type: PAY_BILLS_ACTIONS_TYPES.SELECTED_WALLETS,
        payload: walletList,
      });

      const selectedWallet = walletList.find(
        wallet => wallet.Default === 'YES',
      );

      if (selectedWallet) {
        setCurrentWalletOption(selectedWallet);
      }
    }
  }, [walletList]);

  useEffect(() => {
    if (selectedCountry) {
      setDynamicAmount({
        amount: '',
      });
    }

    if (selectedCountry || currentProviderOption) {
      setInputField({
        data: [],
        Meta: {},
      });
    }
  }, [selectedCountry, currentProviderOption]);

  useEffect(() => {
    const newList = [];
    const findCountry = providersCountries?.data?.map(item => {
      if (
        item?.CountryCode === selectedCountry ||
        item?.CountryCode === currentUser?.OperationCountry
      ) {
        newList.push(item);
      }
    });
    setGetSelectedCountry(newList[0]);

    const countries = providersCountries?.data?.filter(country => {
      return (
        country?.CountryCode === selectedCountry ||
        country?.CountryCode === currentUser?.OperationCountry
      );
    });

    dispatchPayBills({
      type: PAY_BILLS_ACTIONS_TYPES.FETCH_SELECTED_COUNTRY,
      payload: {
        countries: providersCountries?.data,
        operationCountry: currentUser?.OperationCountry,
        selectedCountry: selectedCountry,
      },
    });

    dispatchPayBills({
      type: PAY_BILLS_ACTIONS_TYPES.FETCH_PROVIDERS,
      payload: {
        providers: providersData?.data,
        billsCategories: `${location?.category}`,
      },
    });
  }, [
    selectedCountry,
    currentUser?.OperationCountry,
    providersCountries?.data,
    providersData?.data,
  ]);

  const billsCategoriesElements = useCallback(billCategories => {
    setBillsCategoriesList(() =>
      billsCategories?.flatMap(billCategory => {
        return {
          Category: billCategory.Category,
          IconType: billCategory.IconType,
          Name: billCategory.Name.toLowerCase(),
        };
      }),
    );
  });

  const providersCountriesElements = useCallback(
    providerCountries => {
      setCountriesList(() =>
        providerCountries.flatMap(providerCountry => {
          return {
            key: providerCountry?.CountryCode,
            text: ItemWithFlag({ item: providerCountry }),
            value: providerCountry?.CountryCode,
          };
        }),
      );
    },
  );

  useEffect(() => {
    if (billsCategories) {
      billsCategoriesElements(billsCategories);
    }
    if (providersCountries?.data) {
      providersCountriesElements(providersCountries?.data);
    } else {
      getBillsCategoriesListData({
        CountryCode: location?.country?.toUpperCase(),
      })(dispatch);
      getProvidersCountries()(dispatch);
    }
  }, [billsCategories, providersCountries?.data]);

  useEffect(() => {
    getProviders({
      CountryCode: selectedCountry || currentUser?.OperationCountry,
      ActionType: PAY_BILL_ACTION_TYPE,
      IncludeSelf: 'YES',
    })(dispatch);
  }, [selectedCountry, providersCountries?.data]);

  useEffect(() => {
    if (!currentProviderOption) {
      setAlertMsg(true);
    } else {
      setAlertMsg(false);
    }
  }, [currentProviderOption]);

  const onShowConfirmModal = () => {
    setOpenModal(true);
  };

  const backBtn = () => {
    setNextStep(false);
    setDynamicAmount({
      amount: '',
    });
    setCurrentProviderOption();
    clearVerifyGateway()(dispatch);
    clearGatewayTransferConfirmation()(dispatch);
    clearSendFundToGatewayStore()(dispatch);
  };

  const onHandleInput = (event, item) => {
    const { name, value } = event.target;
    const list = { ...form };
    list[name] = value;
    setForm(list);
  };

  const onHandleChangeDynamicAmount = event => {
    const { name, value } = event.target;
    const list = { ...dynamicAmount };
    list[name] = value;

    setDynamicAmount(list);
  };

  const [key, setKey] = useState();

  useEffect(() => {
    const newMap = {};
    if (currentProviderOption?.Fields) {
      const dynamicFields = currentProviderOption?.Fields;

      let newPhone = {};
      const generatePhoneNumber = inputField?.data?.map(item => {
        if (item?.key === 'phoneNumber') {
          newPhone = {
            format: item?.format,
            key: item?.key,
            label: item.label,
            regex: item.regex,
            required: item.required,
            type: item.type,
            value: form?.phoneNumber,
          };
        }
      });

      if (
        convertStringToArray(dynamicFields) &&
        Array.isArray(convertStringToArray(dynamicFields))
      ) {
        const generateForm = convertStringToArray(dynamicFields)?.map(
          item => {
            newMap[item?.key] = '';
          },
        );

        setForm(newMap);
        setInputField({
          data: convertStringToArray(dynamicFields),
          Meta: undefined,
          key: form?.service,
        });
      }

      if (
        convertStringToArray(dynamicFields) &&
        Array.isArray(convertStringToArray(dynamicFields)) === false
      ) {
        const generateForm = convertStringToArray(
          dynamicFields,
        )?.data?.map(item => {
          newMap[item?.key] = '';
        });

        setForm(newMap);
        setInputField({
          data: convertStringToArray(dynamicFields)?.data,
          Meta: convertStringToArray(dynamicFields)?.meta,
          key: form?.service,
        });
      }

      if (Object.keys(verifiedGatewayAccount?.data).length !== 0) {
        let newInputs = [];
        const newFormattedInput = [];
        const generateForm = verifiedGatewayAccount?.data[0]?.Data?.map(
          item => {
            newMap[item?.key] = '';
          },
        );

        setForm({ ...form, ...newMap });

        const newInputFields = inputField?.data
          .concat([newPhone])
          .map((item, index) => {
            if (index === 1) {
              return item;
            } else {
              newInputs.push(item);
            }
          });

        const formatInputs = newInputs.map(item => {
          if (item.key in form) {
            newFormattedInput.push({
              ...item,
              ...{ value: form[item.key] || '' },
            });
          }
        });

        setInputField({
          data: [
            ...newFormattedInput,
            ...verifiedGatewayAccount?.data[0]?.Data,
          ],
          Meta: {
            amountType: 'done',
          },
          key: form?.service,
        });
      }
    }
  }, [currentProviderOption?.Fields, verifiedGatewayAccount?.data]);

  useEffect(() => {
    if (transactionConfirmation?.data && nextStep === true) {
      setNextStep(true);
      setGetSendFundGateway(true);
    }

    if (
      requestFundsToGateway?.data &&
      requestFundsToGateway?.data[0]?.FeedBackRequired === 'YES'
    ) {
      setNextStep(false);
      setUpdateOperationStatus({ isPending: true });
      setGetSendFundGateway(false);
      clearVerifyGateway()(dispatch);
      clearFailedOperationData()(dispatch);

      setPartnerResponse(
        convertStringToArray(
          requestFundsToGateway?.data[0]?.PartnerResponse,
        ),
      );
    }

    if (
      requestFundsToGateway?.data &&
      requestFundsToGateway?.data[0]?.FeedBackRequired === 'NO'
    ) {
      if (requestFundsToGateway?.data[0]?.Voucher) {
        setTimeout(() => {
          dispatchPayBills({
            type: 'openResult2Modal',
            size: 'mini',
          });
          Swal.close();
        }, 5000);
      } else {
        setNextStep(false);
        setUpdateOperationStatus({ isPending: true });
        setGetSendFundGateway(false);
        clearGatewayTransferConfirmation()(dispatch);
        clearVerifyGateway()(dispatch);
        clearFailedOperationData()(dispatch);
        clearSendFundToGatewayStore()(dispatch);
      }
    }

    if (operations) {
      setNextStep(false);
      setUpdateOperationStatus({
        failed: false,
        isPending: false,
        success: false,
      });
      // history.push('/pay-bills');
      // setDynamicAmount({
      //   amount: '',
      // });
      clearGatewayTransferConfirmation()(dispatch);
      clearSendFundToGatewayStore()(dispatch);
      clearFailedOperationData()(dispatch);
      clearVerifyGateway()(dispatch);
    }

    if (walletUpdate?.data) {
      setNextStep(false);
      setUpdateOperationStatus({
        failed: false,
        isPending: false,
        success: true,
      });
      history.push('/pay-bills');
      clearWalletUpdateData()(dispatch);
      clearSendFundToGatewayStore()(dispatch);
      clearVerifyGateway()(dispatch);
    }
  }, [
    transactionConfirmation?.data,
    operations,
    walletUpdate?.data,
    requestFundsToGateway?.data,
  ]);

  useEffect(() => {
    if (verifiedGatewayAccount?.error) {
      toast.error(`${verifiedGatewayAccount?.error?.Description}`);
      clearVerifyGateway()(dispatch);
      setNextStep(false);
    }
  }, [verifiedGatewayAccount?.error]);

  useEffect(() => {
    if (
      requestFundsToGateway.data &&
      updateOperationStatus.isPending
    ) {
      displayPopup({
        title: global?.translate('Pending'),
        text: global?.translate(
          requestFundsToGateway?.data[0]?.Description,
        ),
        icon: 'warning',
        showConfirmButton: false,
        showCancelButton: false,
      });

      setTimeout(() => {
        dispatchPayBills({ type: 'openResult2Modal', size: 'mini' });
        Swal.close();
      }, 3000);
    }
  }, [updateOperationStatus]);

  useEffect(() => {
    let amount = '';
    let currencyCode = '';
    if (getDropdownOptionValue) {
      const data = getDropdownOptionValue.map(item => {
        if (item?.value === getDropdownInput) {
          amount = item?.amount;
          currencyCode = item?.currency;
        }
      });
    }
    setGetAmountFromOption({
      amount: amount,
      currencyCode: currencyCode,
    });
  }, [getDropdownOptionValue, getDropdownInput]);

  const status = currentProviderOption?.PrePaid;

  const targetCountryCode =
    getSelectedCountry?.CountryCode || currentUser?.OperationCountry;

  const onGatewayTransactionConfirmation = () => {
    const gatewayOperationType =
      location.category === '21'
        ? GATEWAY_PREPAID_CARD
        : status === 'YES'
        ? GATEWAY_INVOICE_PREPAID
        : GATEWAY_INVOICE_POSTPAID;
    const data = {
      Amount:
        getAmountFromOption?.amount ||
        dynamicAmount?.amount ||
        getAmount?.amount ||
        '',
      TargetType: SUPPLIERS_PAY_BILLS,
      GatewayOperationType: gatewayOperationType,
      TargetCountryCode: targetCountryCode,
      Category: location.category,
      ActionType: PAY_BILL_ACTION_TYPE,
      BrandUnikID: currentProviderOption?.BrandUnikID,
      ShortName:
        operatorShortName || currentProviderOption?.ShortName,
      SourceWallet: currentWalletOption?.AccountNumber,
      PartnerRequest: {
        merchant: currentProviderOption?.Title.split(' ')[0]
          .toUpperCase,
        phoneNumber: form?.phoneNumber || currentUser?.MainPhone,
        ...form,
        ...{
          // ! TODO : Need refactor
          serviceNumber: tel
            ? form?.serviceNumber === undefined
              ? `${
                  form?.phoneNumber !== undefined
                    ? currentUser?.OperationCountryPhonePrefix
                    : ' '
                }${form?.phoneNumber || ''}`
              : `${
                  form?.serviceNumber !== undefined
                    ? currentUser?.OperationCountryPhonePrefix
                    : ' '
                }${form?.serviceNumber || ''}`
            : form?.serviceNumber,
        },
      },
      SendVoucher: currentProviderOption?.AcceptVoucher,
      TargetPhoneNumber: selectedContact?.PhoneNumber || '',
      FirstName: selectedContact?.FirstName,
      LastName: selectedContact?.LastName,
      ContactPID: selectedContact?.ContactPID || '',
    };
    gatewayTransferConfirmationData(data)(dispatch);
    setNextStep(true);
  };

  const onVerifyGatewayAccount = () => {
    const countryCode =
      getSelectedCountry?.CountryCode ||
      currentUser?.OperationCountry;
    const payload = {
      PID: currentUser?.PID,
      CountryCode: countryCode,
      OperatorID: currentProviderOption?.OperatorID,
      BrandUnikID: currentProviderOption?.BrandUnikID,
      VerifyURL: inputField?.Meta?.verifyURL || '',
      AccountNumber: {
        ...form,
        ...{
          serviceNumber: tel
            ? form?.serviceNumber === undefined
              ? `${
                  form?.phoneNumber !== undefined
                    ? currentUser?.OperationCountryPhonePrefix
                    : ' '
                }${form?.phoneNumber || ''}`
              : `${
                  form?.serviceNumber !== undefined
                    ? currentUser?.OperationCountryPhonePrefix
                    : ' '
                }${form?.serviceNumber || ''}`
            : form?.serviceNumber,
        },
        phoneNumber:
          form?.phoneNumber === undefined
            ? ''
            : `${currentUser?.OperationCountryPhonePrefix}${form?.phoneNumber}`,
      },
    };

    dispatchPayBills({
      type: PAY_BILLS_ACTIONS_TYPES.REQUEST_GATEWAY_VERIFY_ACCOUNT,
      payload: {
        data: payload,
        dispatch: dispatch,
        request: verifyGatewayAccount,
      },
    });
  };

  const onSubmit = () => {
    const data = {
      PIN: getPin,
      TargetType: MNO_GETAWAY_OPERATOR || '',
      GatewayOperationType:
        location.category === '21'
          ? GATEWAY_PREPAID_CARD
          : status === 'YES'
          ? GATEWAY_INVOICE_PREPAID
          : GATEWAY_INVOICE_POSTPAID,
      Category: location.category,
      BrandUnikID: currentProviderOption?.BrandUnikID,
      SourceWallet: currentWalletOption?.AccountNumber,
      ShortName:
        operatorShortName || currentProviderOption?.ShortName,
      Amount:
        getAmountFromOption?.amount ||
        dynamicAmount?.amount ||
        getAmount.amount,
      ActionType: PAY_BILL_ACTION_TYPE,
      OperatorID: currentProviderOption?.OperatorID,
      OperatorName: currentProviderOption?.Title,
      ServiceRef: '',
      TargetCountryCode:
        getSelectedCountry?.CountryCode ||
        currentUser?.OperationCountry,
      PartnerRequest: {
        ...form,
        ...{
          serviceNumber: tel
            ? form?.serviceNumber === undefined
              ? `${
                  form?.phoneNumber !== undefined
                    ? currentUser?.OperationCountryPhonePrefix
                    : ' '
                }${form?.phoneNumber || ''}`
              : `${
                  form?.serviceNumber !== undefined
                    ? currentUser?.OperationCountryPhonePrefix
                    : ' '
                }${form?.serviceNumber || ''}`
            : form?.serviceNumber,
        },
        merchant:
          operatorShortName ||
          currentProviderOption?.ShortName ||
          currentProviderOption?.OperatorName,
        phoneNumber: currentUser?.MainPhone,
      },
      SendVoucher: currentProviderOption?.AcceptVoucher,
      TargetPhoneNumber:
        selectedContact?.PhoneNumber ||
        (getUser?.data && getUser?.data[0]?.PhoneNumber) ||
        '',
      FirstName:
        selectedContact?.FirstName ||
        (getUser?.data && getUser?.data[0]?.FirstName),
      LastName:
        selectedContact?.LastName ||
        (getUser?.data && getUser?.data[0]?.LastName),
      ContactPID:
        selectedContact?.ContactPID ||
        (getUser?.data && getUser?.data[0]?.PID) ||
        '',
    };
    sendFundsToGatewayData(data)(dispatch);
  };

  const responseData = requestFundsToGateway.data
    ? requestFundsToGateway.data[0]
    : {};

  const onSearchUser = () => {
    findUser({ WalletNumber: selectedUserAccount })(dispatch);
  };

  const onClearFoundUser = () => {
    clearFoundUser()(dispatch);
    setSelectedUserAccount('');
  };

  useEffect(() => {}, [locateUser?.data]);

  const onHandleGetUser = e => {
    setSelectedUserAccount(e.target.value);
  };

  useEffect(() => {
    if (locateUser) {
      dispatchPayBills({
        type: PAY_BILLS_ACTIONS_TYPES.FETCH_LOCATED_USERS,
        payload: locateUser,
      });
    }
  }, [locateUser]);

  return (
    <>
      <BillsCategories
        onSubmit={onSubmit}
        nextStep={nextStep}
        setNextStep={setNextStep}
        openModal={openModal}
        PIN={PIN}
        setPIN={setPIN}
        onShowConfirmModal={onShowConfirmModal}
        backBtn={backBtn}
        walletList={walletList}
        currentWalletOption={currentWalletOption}
        setCurrentWalletOption={setCurrentWalletOption}
        providersCountries={providersCountries}
        setSelectedCountry={setSelectedCountry}
        selectedCountry={selectedCountry}
        countriesList={countriesList}
        defaultCountry={location?.country?.toUpperCase()}
        providers={providers}
        setGetSuppliers={setGetSuppliers}
        currentProviderOption={currentProviderOption}
        setCurrentProviderOption={setCurrentProviderOption}
        getSelectedCountry={getSelectedCountry}
        alertMsg={alertMsg}
        inputField={inputField}
        form={form}
        onHandleInput={onHandleInput}
        setGetDropdownInput={setGetDropdownInput}
        getDropdownInput={getDropdownInput}
        setGetDropdownOptionValue={setGetDropdownOptionValue}
        getDropdownOptionValue={getDropdownOptionValue}
        getAmountFromOption={getAmountFromOption}
        billsCategoriesList={billsCategoriesList}
        setStartDate={setStartDate}
        startDate={startDate}
        gatewayTransactionConfirmation={transactionConfirmation}
        onGatewayTransactionConfirmation={
          onGatewayTransactionConfirmation
        }
        confirmTransactionPin={confirmTransactionPin}
        setConfirmTransactionPin={setConfirmTransactionPin}
        setGetPin={setGetPin}
        sendFundsToGateway={requestFundsToGateway}
        setDynamicAmount={setDynamicAmount}
        dynamicAmount={dynamicAmount}
        onHandleChangeDynamicAmount={onHandleChangeDynamicAmount}
        onVerifyGatewayAccount={onVerifyGatewayAccount}
        verifiedGatewayAccount={verifiedGatewayAccount}
        setForm={setForm}
        updateOperationStatus={updateOperationStatus}
        pageName={location?.operationName}
        getSendFundGateway={getSelectedCountry}
        setGetSendFundGateway={setGetSendFundGateway}
        setGetAmount={setGetAmount}
        getAmount={getAmount}
        setGetAmountFromOption={setGetAmountFromOption}
        setInputField={setInputField}
        setTel={setTel}
        size={size}
        open={open}
        openResult2Modal={openResult2Modal}
        dispatchPayBills={dispatchPayBills}
        responseData={responseData}
        partnerResponse={partnerResponse?.data}
        history={history}
        contactFound={contactFound}
        setContactFound={setContactFound}
        setSelectedContact={setSelectedContact}
        setOperatorShortName={setOperatorShortName}
        onHandleGetUser={onHandleGetUser}
        onSearchUser={onSearchUser}
        onClearFoundUser={onClearFoundUser}
        getUser={getUser}
        selectedUserAccount={selectedUserAccount}
      />
    </>
  );
};

const convertStringToArray = string => {
  const generalFieldsFromString = string !== '' ? string : '[]';
  const result = JSON.parse(generalFieldsFromString);
  return result;
};

export default React.memo(BillsCategoriesContainer);
