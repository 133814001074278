import {
  GET_SAVINGS_KPI_START,
  GET_SAVINGS_KPI_SUCCESS,
  GET_SAVINGS_KPI_ERROR,
} from 'constants/action-types/organization';

export default (state, { type, payload }) => {
  switch (type) {
    case GET_SAVINGS_KPI_START:
      return {
        ...state,
        savingsKPI: {
          ...state.savingsKPI,
          loading: true,
        },
      };
    case GET_SAVINGS_KPI_SUCCESS:
      return {
        ...state,
        savingsKPI: {
          ...state.savingsKPI,
          ...payload,
          loading: false,
        },
      };
    case GET_SAVINGS_KPI_ERROR:
      return {
        ...state,
        savingsKPI: {
          ...state.savingsKPI,
          loading: false,
          error: payload.error,
        },
      };
    default:
      return null;
  }
};
