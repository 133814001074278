import React, { useEffect, useState } from 'react';
import { Button, Form, Image, Input } from 'semantic-ui-react';
import WalletDropDown from 'components/common/WalletDropDown';
import LoadWalletImg from 'assets/images/withdraw/load-wallet.svg';
import { useDispatch, useSelector } from 'react-redux';
import findUser from 'redux/actions/contacts/locateWallet';
import bankIcon from 'assets/images/bankIcon.svg';
import PhoneInput from 'react-phone-input-2';
import ConfirmationPage from './confirmationPage';
import ossixLogo from 'assets/images/simple-logo.svg';
import circleIcon from 'assets/images/circleIcon.svg';
import circleIconChecked from 'assets/images/circleIconChecked.svg';
import ToOthers from './toOthers';
import SelfWithdraw from './selfWithdraw';
import ShowCurrentBox from '../common/showCurrentBox';
import ContentBox from 'components/common/ContentBox';

const WithdrawCash = ({
  nextStep,
  currentPage,
  walletList,
  currentOption,
  setCurrentOption,
  setCurrentPage,
  setTargetWallet,
  targetWallet,
  amount,
  setAmount,
  userData,
  setForm,
  form,
  onSubmit,
  option,
  setOption,
  onHandleChangeAmount,
  checking,
  setGetPin,
  confirmationData,
  confirmTransactionPin,
  setConfirmTransactionPin,
  onSubmitWalletTransactionConfirmation,
  onMoveFounds,
  moveFundsTo2UWalletLoading,
}) => {
  const dispatch = useDispatch();
  const [enableNextProcess, setEnableNextProcess] = useState(true);
  const [
    selectedAccountNumber,
    setSelectedAccountNumber,
  ] = useState();

  const { locateWallet } = useSelector(({ contacts }) => contacts);
  const walletFound = locateWallet?.data;

  const onHandleGetAccountNumber = e => {
    setSelectedAccountNumber(e.target.value);
  };

  const onSearchAccountNumber = () => {
    findUser({ WalletNumber: selectedAccountNumber })(dispatch);
  };

  useEffect(() => {
    if (walletFound && form?.amount) {
      setEnableNextProcess(false);
      const wallets = walletFound?.map(item => {
        setTargetWallet(item);
      });
    }
  }, [walletFound, form?.amount]);

  useEffect(() => {
    const wallet = walletFound?.map(item => {
      setForm({
        firstName: item?.FirstName,
        lastName: item?.LastName,
      });
    });
  }, [walletFound]);

  return (
    <>
      <ContentBox title="Withdraw money">
        <ShowCurrentBox steps={nextStep} />
        <>
          <Form>
            <h3 className="page-title">
              {global.translate('Withdraw cash')}
            </h3>
            {[0, 1, 2].includes(currentPage) ? (
              <div>
                {walletList?.length !== 0 ? (
                  <>
                    <div className="cash-out-title">
                      {global.translate('Select wallet')}
                    </div>
                    <div className="wallet-select">
                      <WalletDropDown
                        walletList={walletList}
                        setCurrentOption={setCurrentOption}
                        currentOption={currentOption}
                      />
                    </div>
                  </>
                ) : (
                  <div
                    className="load-data"
                    style={{
                      paddingTop: '8px',
                      paddingBottom: '12px',
                    }}
                  >
                    <Image
                      src={LoadWalletImg}
                      className="animate-placeholder"
                    />
                  </div>
                )}
              </div>
            ) : null}
          </Form>
          {currentPage === 0 ? (
            <>
              <div className="card-box">
                <div
                  className="card-box__items"
                  onClick={() => setOption(1)}
                >
                  <Image src={ossixLogo} style={{ width: '40px' }} />
                  <div className="card-box__items-left">
                    <div className="items_content">
                      <span className="btn-title__top">
                        {global.translate('Select contact')}
                      </span>
                      <span className="btn-title__bottom">
                        {global.translate(
                          'Send cash to be picked up to a cashpoint',
                        )}
                      </span>
                    </div>
                    <Image
                      src={
                        option === 1 ? circleIconChecked : circleIcon
                      }
                    />
                  </div>
                  <div></div>
                </div>
                <div
                  className="card-box__items"
                  onClick={() => setOption(2)}
                >
                  <Image src={bankIcon} />
                  <div className="card-box__items-left">
                    <div className="items_content">
                      <span className="btn-title__top">
                        {global.translate('Self-withdraw money')}
                      </span>
                      <span className="btn-title__bottom">
                        {global.translate(
                          'Withdraw cash using your phone number',
                        )}
                      </span>
                    </div>
                    <Image
                      src={
                        option === 2 ? circleIconChecked : circleIcon
                      }
                    />
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {currentPage === 1 ? (
            <ToOthers
              selectedAccountNumber={selectedAccountNumber}
              onHandleGetAccountNumber={onHandleGetAccountNumber}
              locateWallet={locateWallet}
              onSearchAccountNumber={onSearchAccountNumber}
              walletFound={walletFound}
              onHandleChangeAmount={onHandleChangeAmount}
              userData={userData}
              form={form}
              enableNextProcess={enableNextProcess}
              currentOption={currentOption}
              onSubmitWalletTransactionConfirmation={
                onSubmitWalletTransactionConfirmation
              }
              setCurrentPage={setCurrentPage}
              setForm={setForm}
              loading={checking}
            />
          ) : null}

          {currentPage === 2 ? (
            <SelfWithdraw
              selectedAccountNumber={selectedAccountNumber}
              onHandleGetAccountNumber={onHandleGetAccountNumber}
              locateWallet={locateWallet}
              onSearchAccountNumber={onSearchAccountNumber}
              walletFound={walletFound}
              onHandleChangeAmount={onHandleChangeAmount}
              userData={userData}
              form={form}
              currentOption={currentOption}
              onSubmitWalletTransactionConfirmation={
                onSubmitWalletTransactionConfirmation
              }
              setCurrentPage={setCurrentPage}
              setForm={setForm}
              loading={checking}
            />
          ) : null}

          {currentPage === 0 ? (
            <div className="bottom-transition__box">
              <div></div>
              <Button
                onClick={onSubmit}
                disabled={
                  option !== undefined && walletList?.length !== 0
                    ? false
                    : true
                }
                style={{ marginLeft: '20px' }}
              >
                {global.translate('Proceed')}
              </Button>
            </div>
          ) : null}

          {currentPage === 4 ? (
            <ConfirmationPage
              transactionConfirmationData={confirmationData}
              confirmTransactionPin={confirmTransactionPin}
              setConfirmTransactionPin={setConfirmTransactionPin}
              setCurrentPage={setCurrentPage}
              onMoveFounds={onMoveFounds}
              setGetPin={setGetPin}
              loading={checking}
              currentPage={currentPage}
              option={option}
              loading={moveFundsTo2UWalletLoading}
            />
          ) : null}
        </>
      </ContentBox>
    </>
  );
};

export default WithdrawCash;
