import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Input,
  Dropdown,
  Icon,
  Image,
  TextArea,
  Form,
  Checkbox,
  Label,
} from 'semantic-ui-react';
import ShowCurrentBox from '../../Gimac/common/showCurrentBox';
import QrReader from 'react-qr-reader';
import ContentBox from 'components/common/ContentBox';
import WalletDropDown from 'components/common/WalletDropDown';
import ReusableDropdown from 'components/common/Dropdown/ReusableDropdown';
import PinCodeForm from 'components/common/PinCodeForm';
import LoadWalletImg from 'assets/images/withdraw/load-wallet.svg';
import { useDispatch, useSelector } from 'react-redux';
import loadTransactions from 'assets/images/transactions/load-transactions.svg';
import { clearGatewayTransferConfirmation } from 'redux/actions/moneyTransfer/gatewayTransferConfirmationData';
import DatePicker from 'components/common/DatePicker';
import dayjs from 'dayjs';
import CustomModal from 'components/Organization/common/customModal';
import { BsFilePdfFill } from 'react-icons/bs';
import saveTemporarily from 'helpers/uploadImages/saveTemporarily';
import { clearVerifyGateway } from 'redux/actions/payBills/verifyGatewayAccount';
import './style.scss';
import { clearSendFundToGatewayStore } from 'redux/actions/moneyTransfer/sendFundsToGatewayData';

const { REACT_APP_FILE_SERVICES_URL } = process.env;

const BillsCategories = props => {
  const {
    nextStep,
    onSubmit,
    setNextStep,
    backBtn,
    walletList,
    currentWalletOption,
    setCurrentWalletOption,
    providersCountries,
    selectedCountry,
    setSelectedCountry,
    countriesList,
    defaultCountry,
    currentProviderOption,
    setCurrentProviderOption,
    providers,
    alertMsg,
    inputField,
    form,
    setForm,
    onHandleInput,
    getSelectedCountry,
    getDropdownInput,
    getAmountFromOption,
    billsCategoriesList,
    gatewayTransactionConfirmation,
    onGatewayTransactionConfirmation,
    confirmTransactionPin,
    setConfirmTransactionPin,
    setGetPin,
    sendFundsToGateway,
    dynamicAmount,
    onHandleChangeDynamicAmount,
    onVerifyGatewayAccount,
    verifiedGatewayAccount,
    pageName,
    setGetAmount,
    getAmount,
    setInputField,
    setTel,
    size,
    open,
    dispatchPayBills,
    openResult2Modal,
    responseData,
    partnerResponse,
    history,
    setContactFound,
    contactFound,
    setSelectedContact,
    setDynamicAmount,
    setOperatorShortName,
    onHandleGetUser,
    onSearchUser,
    onClearFoundUser,
    getUser,
    selectedUserAccount,
  } = props;
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const [submitBtn, setSubmitBtn] = useState(true);
  const [selectDate, setSelectDate] = useState(null);
  const [saveDocument, setSaveDocument] = useState();
  const [checked, setChecked] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const { allContacts } = useSelector(state => state.contacts);
  const walletFound = allContacts?.data;
  const [scanAmount, setScanAmount] = useState(false);
  const [inputLabel, setInputLabel] = useState(null);
  const [scanNarrative, setScanNarrative] = useState(false);
  const [data, setData] = useState('No result');
  const [scanedAmount, setScanedAmount] = useState(null);
  const [scanedAccount, setScanedAccount] = useState(null);
  const [scanedNarrative, setScanedNarrative] = useState(null);
  const [imageSource, setImageSource] = useState(false);

  const onChange = ({ target: { name, value }, input }) => {
    setConfirmTransactionPin({
      ...confirmTransactionPin,
      [name]: value,
    });
  };

  useEffect(() => {
    if (confirmTransactionPin) {
      const {
        digit0,
        digit1,
        digit2,
        digit3,
        digit4,
        digit5,
      } = confirmTransactionPin;
      const value = `${digit0 || ''}${digit1 || ''}${digit2 ||
        ''}${digit3 || ''}${digit4 || ''}${digit5 || ''}`;
      if (value.length === 6) {
        setSubmitBtn(false);
        setGetPin(value);
      }
    }
  }, [confirmTransactionPin]);

  const [saveDropdownData, setSaveDropdownData] = useState();
  const [optionItems, setOptionItems] = useState();
  const [selectedService, setSelectedService] = useState([]);
  const previousValue = useRef();

  const onDynamicDropdownChange = (data, item1, index, item) => {
    const operatorShortName = item1?.options?.filter(
      option => option?.value === item1?.value,
    )?.[0]?.operatorShortName;

    setOptionItems(
      () =>
        item1?.options?.filter(
          option => option?.value === item1.value,
        )[0],
    );
    if (operatorShortName) {
      setOperatorShortName(operatorShortName);
    }

    if (
      item1?.options?.filter(
        option => option?.value === item1?.value,
      )?.[0]?.amount
    ) {
      setGetAmount(
        () =>
          item1?.options?.filter(
            option => option?.value === item1.value,
          )[0],
      );
    }
    const list = { ...saveDropdownData };
    // ! TO BE REMOVED
    if (item?.key === 'tariff') {
      delete item?.key;
    }
    list[item?.key] = item1.value;
    setSaveDropdownData(list);

    if (index === 0 && item?.key === 'service') {
      let selectedElement = [...selectedService];
      selectedElement = item1.value;
      setSelectedService([selectedElement]);
    }
  };

  useEffect(() => {
    if (selectedService) {
      previousValue.current = selectedService;
      clearVerifyGateway()(dispatch);
    }
  }, [selectedService]);

  useEffect(() => {
    if (previousValue.current && inputField?.data?.length > 2) {
      clearVerifyGateway()(dispatch);
    }
  }, [previousValue.current]);

  useEffect(() => {
    if (saveDropdownData) {
      setForm({ ...form, ...saveDropdownData });
    }
  }, [saveDropdownData]);

  useEffect(() => {
    if (inputField?.data) {
      const defaultInputValue = inputField?.data.filter(
        input => input.value,
      );

      let result = {};
      for (let item = 0; item < defaultInputValue.length; item++) {
        result = {
          ...result,
          ...{
            [defaultInputValue[item]?.key]: defaultInputValue[item]
              ?.value,
          },
        };
      }
      setForm({ ...form, ...result });
    }
  }, [inputField?.data]);

  const [file, setFile] = useState();

  const handleFileChange = event => {
    try {
      const targetFile = event.target.files[0];
      const fileTypes = [
        {
          type: 'application/pdf',
          prefix: 'application/',
        },
        {
          type: 'image/svg+xml',
          prefix: 'image/',
        },
        {
          type: 'image/png',
          prefix: 'image/',
        },
        {
          type: 'image/jpg',
          prefix: 'image/',
        },
        {
          type: 'image/jpeg',
          prefix: 'image/',
        },
        {
          type:
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          prefix: 'application/',
        },
      ];
      let newTypeList = [];

      const reader = new FileReader();

      reader.onload = () => {
        setFile({
          file: reader.result,
          type: newTypeList[0]?.fileType,
          name: targetFile.name,
        });
      };

      reader.readAsDataURL(targetFile);

      const formData = new FormData();
      formData.append('file', targetFile, targetFile?.name);
      setSaveDocument(formData);

      dispatchPayBills({
        type: 'open',
        size: 'big',
      });
    } catch (error) {}
  };

  const uploadImageTemp = async () => {
    const supportImg = await saveTemporarily({
      supportImg: saveDocument,
    });
    dispatchPayBills({ type: 'close' });
    return { data: { link: supportImg } };
  };

  useEffect(() => {
    if (file?.name) {
      setForm({
        ...form,
        ...{
          picture: file?.name
            ? `${REACT_APP_FILE_SERVICES_URL}/file/${file?.name}` ||
              ''
            : undefined,
        },
      });
    }
  }, [file?.name]);

  useEffect(() => {
    const newList = [];
    setDropdownOptions(newList);
  }, [inputField?.data, currentProviderOption]);

  // pageName
  // ! TO BE REMOVED
  const [newOperators, setNewOperators] = useState();
  useEffect(() => {
    const providersList = [];

    setNewOperators(
      () =>
        providers !== undefined &&
        Object.keys(providers).length !== 0 &&
        providers.map(provider => {
          if (provider) {
            return [provider][0];
          }
        }),
    );
  }, [pageName, providers]);

  const handleSearch = (options, query) => {
    return options.filter(opt =>
      opt.text.split(':')[0].includes(query),
    );
  };

  const handleSearchContact = event => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filteredResults = walletFound.filter(item => {
      return (
        item.FirstName.toLowerCase().includes(query) ||
        item.LastName.toLowerCase().includes(query)
      );
    });

    setContactFound(filteredResults);
  };

  const hours = Array(24)
    .fill('*')
    .map((_, hour) => {
      const arr = [];
      for (let minute = 0; minute < 60; minute += 5) {
        arr.push({
          key: `${`0${hour}`.slice(-2)}:${`0${minute}`.slice(-2)}`,
          value: `${`0${hour}`.slice(-2)}:${`0${minute}`.slice(-2)}`,
          text: `${`0${hour}`.slice(-2)}:${`0${minute}`.slice(-2)}`,
        });
      }
      return arr;
    });

  const handleScanAmount = data => {
    if (data) {
      setScanedAmount(data);
      setDynamicAmount({ amount: data });
      setScanAmount(false);
    }
  };
  const handleScanDynamicField = data => {
    if (data) {
      if (inputLabel) {
        setScanedAccount(data);
        setInputField({
          ...inputField,
          data: inputField.data.map(item => {
            return {
              ...item,
              value: inputLabel === item.label ? data : null,
            };
          }),
        });
      }
      if (scanNarrative) {
        setScanedNarrative(data);
      }
      setInputLabel(null);
      setScanNarrative(false);
    }
  };

  const handleDynamicScan = input => {
    if (input === 'Bank account number') {
      setInputLabel('Bank account number');
    }
    if (input === 'Narrative') {
      setInputLabel('Narrative');
    }
    if (input === 'Student ID') {
      setInputLabel('Student ID');
    }
    if (input === 'Student name') {
      setInputLabel('Student name');
    }
    if (input === 'Grade') {
      setInputLabel('Grade');
    }
    if (input === 'Academic year') {
      setInputLabel('Academic year');
    }
    if (input === 'Messageto school') {
      setInputLabel('Messageto school');
    }
  };
  const handleError = err => {
    throw new Error(global.translate('Failed to scan'), err);
  };

  return (
    <>
      {!billsCategoriesList ? (
        <ContentBox
          title={`${global.translate('Pay bills')}: ${pageName}`}
          setNextStep={setNextStep}
          backBtn={backBtn}
        >
          <Image src={loadTransactions} />
        </ContentBox>
      ) : null}
      {billsCategoriesList ? (
        <ContentBox
          title={`${global.translate(
            'Pay bills',
          )}: ${global.translate(pageName)}`}
          setNextStep={setNextStep}
          nextStep={nextStep}
          backBtn={backBtn}
        >
          <ShowCurrentBox steps={nextStep} />
          {nextStep === false ? (
            <>
              <h3 className="page-title">
                {global.translate(`${pageName}`)}
              </h3>
              <div>
                {walletList?.length !== 0 ? (
                  <>
                    <div className="cash-out-title">
                      {global.translate('Select wallet')}
                    </div>
                    <div className="wallet-select">
                      <WalletDropDown
                        walletList={walletList}
                        setCurrentOption={setCurrentWalletOption}
                        currentOption={currentWalletOption}
                      />
                    </div>
                  </>
                ) : (
                  <div
                    className="load-data"
                    style={{
                      paddingTop: '8px',
                      paddingBottom: '12px',
                    }}
                  >
                    <Image
                      src={LoadWalletImg}
                      className="animate-placeholder"
                    />
                  </div>
                )}
              </div>

              <div className="card-box__field">
                <span className="card-title">
                  {global.translate('Select a destination country')}
                </span>

                <div className="input-field">
                  <Dropdown
                    selection
                    loading={providersCountries?.loading}
                    options={countriesList}
                    value={selectedCountry}
                    defaultValue={defaultCountry}
                    size="large"
                    style={{ width: '100%' }}
                    placeholder={
                      providersCountries?.loading === true
                        ? global.translate('Loading countries...')
                        : global.translate('Select a country')
                    }
                    onChange={(data, item) => {
                      setSelectedCountry(item.value);
                    }}
                  />
                </div>
              </div>

              {(alertMsg === true && providers === undefined) ||
              providers?.length === 0 ? (
                <div
                  className="card-box__items-field"
                  style={{ padding: '0px', border: 'none' }}
                >
                  <div className="warning-box">
                    <span>
                      {global.translate('No suppliers available')}
                    </span>
                  </div>
                </div>
              ) : null}

              {providers?.length > 0 ? (
                <div className="card-box__field">
                  <span className="card-title">
                    {global.translate('Select a supplier')}
                  </span>
                  <div className="input-field">
                    <ReusableDropdown
                      options={newOperators}
                      currentOption={currentProviderOption}
                      setCurrentOption={setCurrentProviderOption}
                      placeholder={global.translate(
                        'Select a supplier',
                      )}
                    />
                  </div>
                </div>
              ) : null}

              {checked && (
                <div className="card-box__field">
                  {/* <span className="card-title">
                    {global.translate('Select the recipient')}
                  </span>
                  <div className="card-box__items-field">
                    <div className="card-box__items-field-btn">
                      <Input
                        className="icon"
                        labeled
                        value={searchQuery}
                        onChange={handleSearchContact}
                        placeholder={global.translate(
                          'Select the recipient',
                        )}
                        style={{ border: 'none', width: '90%' }}
                      />
                    </div>
                    <div className="small-separator"></div>

                    <div
                      style={{
                        height: `${
                          contactFound?.length > 0 ? '100px' : '0px'
                        }`,
                        overflow: 'auto',
                      }}
                    >
                      {contactFound &&
                        contactFound?.map(item => (
                          <div
                            className="user-found-box"
                            id="user-found-box"
                            onClick={() => {
                              setSelectedContact(item);
                              setSearchQuery(
                                `${item.FirstName} ${item.LastName}`,
                              );
                              setContactFound([]);
                            }}
                          >
                            {item?.PictureURL !== '' ? (
                              <div
                                className="orval-shape"
                                style={{ background: 'transparent' }}
                              >
                                <Image src={item?.PictureURL} />
                              </div>
                            ) : (
                              <div className="orval-shape">
                                <span className="label">
                                  {`${item?.FirstName}`.charAt(0)}
                                  {`${item?.LastName}`.charAt(0)}
                                </span>
                              </div>
                            )}

                            <div className="items_content">
                              <span className="btn-title__top">
                                {item?.FirstName} {item?.LastName}
                              </span>
                              <span className="btn-title__bottom">
                                {item?.WalletNumber}
                              </span>
                            </div>
                          </div>
                        ))}
                    </div>

                    {!walletFound ? (
                      <div className="warning-box">
                        <span>
                          {global.translate(
                            "You haven't selected any contact yet.",
                          )}
                        </span>
                      </div>
                    ) : null}
                  </div> */}
                  <div className="form-box">
                    <div
                      className="div__separator"
                      style={{
                        marginTop: '-8px',
                        marginBottom: '10px',
                      }}
                    ></div>
                    <div className="card-box__items-field">
                      <div className="card-box__items-field-btn">
                        <Input
                          className="icon"
                          labeled
                          value={selectedUserAccount}
                          onChange={onHandleGetUser}
                          placeholder={global.translate(
                            'Wallet number, username or phone number',
                          )}
                          style={{ border: 'none', width: '90%' }}
                          onKeyDown={e => {
                            if (e.key === 'Enter') {
                              onSearchUser();
                            }
                          }}
                        />
                        {!getUser?.data ? (
                          <Button
                            icon="search"
                            loading={getUser?.loading}
                            onClick={onSearchUser}
                          />
                        ) : (
                          <Button
                            icon="cancel"
                            onClick={() => onClearFoundUser()}
                          />
                        )}
                      </div>
                      <div className="small-separator"></div>
                      {getUser?.data &&
                        getUser?.data?.map((item, index) => {
                          return (
                            <div className="user-found-box">
                              {imageSource === true ? (
                                <div className="orval-shape">
                                  <span className="label">
                                    {`${item?.FirstName}`.charAt(0)}
                                    {`${item?.LastName}`.charAt(0)}
                                  </span>
                                </div>
                              ) : (
                                <div
                                  className="orval-shape"
                                  style={{
                                    background: 'transparent',
                                  }}
                                >
                                  <Image
                                    key={index}
                                    src={item?.PictureURL}
                                    onError={e => {
                                      setImageSource(true);
                                    }}
                                  />
                                </div>
                              )}

                              <div className="items_content">
                                <span className="btn-title__top">
                                  {item?.FirstName} {item?.LastName}
                                </span>
                                <span className="btn-title__bottom">
                                  {item?.WalletNumber}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      {!getUser?.data ? (
                        <div className="warning-box">
                          <span>
                            {global.translate(
                              'You haven’t selected any user yet.',
                            )}
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              )}

              {alertMsg === false
                ? inputField?.data?.map((item, index) => {
                    return (
                      <>
                        {item?.type === 'text' &&
                          form?.Livraison === 'Yes' && (
                            <div className="card-box__field">
                              <span className="card-title">
                                {global.translate(item?.label)}
                              </span>
                              <div className="input-field">
                                <Input
                                  key={index}
                                  type={item?.type}
                                  labelPosition="right"
                                  value={
                                    item?.value || form[item?.key]
                                  }
                                  onChange={event => {
                                    onHandleInput(event, item?.value);
                                  }}
                                  name={item?.key}
                                  placeholder={global.translate(
                                    item?.label,
                                  )}
                                />
                              </div>
                            </div>
                          )}
                        {item?.type === 'text' &&
                          ['', 'Yes', 'No'].includes(
                            form?.Livraison,
                          ) === false && (
                            <div className="card-box__field">
                              <span className="card-title">
                                {global.translate(item?.label)}
                              </span>
                              <div className="input-field flex">
                                <Input
                                  key={index}
                                  labelPosition="right"
                                  value={
                                    item?.value || form[item?.key]
                                  }
                                  onChange={event => {
                                    onHandleInput(event, item?.value);
                                  }}
                                  name={item?.key}
                                  placeholder={global.translate(
                                    item?.label,
                                  )}
                                />
                              </div>
                            </div>
                          )}
                        {item?.type === 'qrcode' && (
                          <>
                            <div className="card-box__field">
                              <span className="card-title">
                                {global.translate(item?.label)}
                              </span>
                              <div className="input-field flex">
                                <Input
                                  key={index}
                                  labelPosition="right"
                                  value={item.value}
                                  onChange={event => {
                                    onHandleInput(event, item?.value);
                                  }}
                                  name={item?.key}
                                  placeholder={global.translate(
                                    item?.label,
                                  )}
                                  maxLength={0}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      position: 'absolute',
                                      right: '6px',
                                      top: '13px',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Icon
                                      onClick={() =>
                                        handleDynamicScan(item?.label)
                                      }
                                      size="large"
                                      style={{
                                        marginRight: '10px',
                                        cursor: 'pointer',
                                      }}
                                      name="qrcode"
                                    />
                                  </div>
                                  <input />
                                </Input>
                              </div>
                            </div>
                            {inputLabel === item?.label && (
                              <div>
                                <QrReader
                                  delay={300}
                                  onError={handleError}
                                  onScan={handleScanDynamicField}
                                  style={{ width: '100%' }}
                                />
                              </div>
                            )}
                          </>
                        )}
                        {item?.type === 'number' ? (
                          <div className="card-box__field">
                            <span className="card-title">
                              {global.translate(item?.label)}
                            </span>
                            <div className="operator-input">
                              <Input
                                key={index}
                                name={item?.key}
                                value={form[item?.key]}
                                onChange={onHandleInput}
                                placeholder={global.translate(
                                  item?.label,
                                )}
                              />
                            </div>
                          </div>
                        ) : null}
                        {item?.type === 'date' ? (
                          <div className="card-box__field">
                            <span className="card-title">
                              {global.translate(item?.label)}
                            </span>
                            <div className="operator-date-input">
                              <DatePicker
                                className="selected-date"
                                key={index}
                                date={selectDate}
                                onDateChange={data => {
                                  setSelectDate(data);
                                  setForm({
                                    ...form,
                                    [item?.key]: dayjs(data).format(
                                      'DD/MM/YYYY',
                                    ),
                                  });
                                }}
                                placeholder={global.translate(
                                  item?.label,
                                )}
                                dateFormat="yyyy-MM-dd"
                              />
                            </div>
                            <br />
                          </div>
                        ) : null}
                        {item?.type === 'time' ? (
                          <div className="card-box__field">
                            <span className="card-title">
                              {global.translate(item?.label)}
                            </span>
                            <div className="input-field">
                              <Dropdown
                                selection
                                search={handleSearch}
                                multiple={item?.multi}
                                options={hours.flat()}
                                value={getDropdownInput}
                                size="large"
                                style={{ width: '100%' }}
                                placeholder={global.translate(
                                  `${item?.label}`,
                                )}
                                onChange={(data, item1) => {
                                  onDynamicDropdownChange(
                                    data,
                                    item1,
                                    index,
                                    item,
                                  );
                                  setTel(item?.type);
                                }}
                              />
                            </div>
                          </div>
                        ) : null}
                        {item?.type === 'file' ? (
                          <div className="card-box__field">
                            <span className="card-title">
                              {global.translate(item?.label)}
                            </span>

                            <div className="operator-input">
                              <div className="input-file-container">
                                <input
                                  key={index}
                                  name={item?.key}
                                  type={'file'}
                                  onChange={handleFileChange}
                                  style={{ width: '100%' }}
                                  placeholder={global.translate(
                                    item?.label,
                                  )}
                                  hidden
                                  id="upload-file"
                                />
                                <label
                                  for="upload-file"
                                  className="file-label"
                                >
                                  {file?.name ??
                                    global.translate('Choose file')}
                                </label>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {item?.type === 'tel' ? (
                          <div className="card-box__field">
                            <span className="card-title">
                              {global.translate(item?.label)}
                            </span>
                            <div
                              className="operator-input"
                              style={{ marginTop: '10px' }}
                            >
                              <Input
                                key={index}
                                labelPosition="right"
                                type="text"
                                name={item?.key}
                                value={form[item?.key]}
                                onChange={event => {
                                  onHandleInput(event);
                                  setTel(item?.type);
                                }}
                                placeholder={global.translate(
                                  `${item?.label}`,
                                )}
                                className="custom-input-image"
                              >
                                <Image
                                  src={getSelectedCountry?.Flag}
                                />
                                <span>
                                  {getSelectedCountry?.PhoneAreaCode}
                                </span>
                                <input />
                              </Input>
                            </div>
                          </div>
                        ) : null}

                        {['dropdown', 'checkbox'].includes(
                          item?.type,
                        ) &&
                          !item?.category && (
                            <div className="card-box__field">
                              <span className="card-title">
                                {global.translate(`${item?.label}`)}
                              </span>
                              {/* {item.key} */}
                              <Dropdown
                                selection
                                key={item.key}
                                multiple={item?.multi}
                                options={
                                  item?.options?.map((itm, key) => ({
                                    ...itm,
                                    key,
                                    value: itm.value,
                                    text: (
                                      <div
                                        className="item-with-flag"
                                        index={key}
                                      >
                                        {itm?.logo ||
                                        currentProviderOption?.Img ? (
                                          <img
                                            src={
                                              itm?.logo ||
                                              currentProviderOption?.Img
                                            }
                                          />
                                        ) : null}
                                        <span>{itm?.text}</span>
                                      </div>
                                    ),
                                  })) || dropdownOptions
                                }
                                value={getDropdownInput}
                                size="large"
                                style={{ width: '100%' }}
                                placeholder={global.translate(
                                  `${item?.label}`,
                                )}
                                onChange={(data, item1) => {
                                  onDynamicDropdownChange(
                                    data,
                                    item1,
                                    index,
                                    item,
                                  );
                                }}
                              />
                            </div>
                          )}
                        {/* ! TO BE REMOVED */}
                        {item?.type === 'textarea' ? (
                          <>
                            <div className="card-box__field">
                              <span className="card-title">
                                {global.translate(`${item?.label}`)}
                              </span>

                              <Form>
                                <TextArea
                                  row={2}
                                  key={index}
                                  name={item?.key}
                                  value={form[item?.key]}
                                  onChange={onHandleInput}
                                  placeholder={global.translate(
                                    `${item?.label}`,
                                  )}
                                  style={{
                                    minHeight: 100,
                                    width: '100%',
                                  }}
                                />
                              </Form>
                            </div>
                          </>
                        ) : null}
                      </>
                    );
                  })
                : null}
              {currentProviderOption?.AcceptVoucher === 'YES' && (
                <div className="card-box__field">
                  <span className="card-title">
                    {global.translate('Send voucher ?')}
                  </span>
                  <div className="input-field">
                    <Checkbox
                      label="Voucher"
                      onChange={(e, data) => setChecked(data.checked)}
                      checked={checked}
                    />
                  </div>
                </div>
              )}
              {(inputField?.Meta !== undefined &&
                inputField?.Meta?.verifyAccount === true) ||
              inputField?.Meta?.amountType === 'done' ||
              inputField?.Meta?.amountType === 'qrcode' ? (
                null || getAmount?.amountType === 'FIXED'
              ) : (
                <div className="card-box__field">
                  <span className="card-title">
                    {global.translate('Amount')}
                  </span>
                  <div className="operator-input">
                    <Input
                      name="amount"
                      label={{
                        basic: true,
                        content: currentWalletOption?.CurrencyCode,
                      }}
                      labelPosition="right"
                      disabled={
                        alertMsg ||
                        getAmount?.amountType === 'FIXED' ||
                        inputField?.Meta?.amountType?.toLowerCase() ===
                          'fixed'
                          ? true
                          : alertMsg
                      }
                      value={
                        getAmountFromOption?.amount ||
                        dynamicAmount?.amount ||
                        getAmount?.amount
                      }
                      onChange={
                        getAmountFromOption?.amount === undefined
                          ? null
                          : onHandleChangeDynamicAmount
                      }
                      placeholder={global.translate('Amount')}
                    />
                  </div>
                </div>
              )}

              {inputField?.Meta?.amountType === 'done' ? (
                <div className="card-box__field">
                  <span className="card-title">
                    {global.translate('Amount')}
                  </span>
                  <div className="operator-input">
                    <Input
                      name="amount"
                      label={{
                        basic: true,
                        content: currentWalletOption?.CurrencyCode,
                      }}
                      disabled={
                        alertMsg || getAmount?.amountType === 'FIXED'
                          ? true
                          : alertMsg
                      }
                      labelPosition="right"
                      value={
                        getAmountFromOption?.amount ||
                        dynamicAmount?.amount ||
                        getAmount?.amount
                      }
                      onChange={
                        getAmountFromOption?.amount === undefined
                          ? null
                          : onHandleChangeDynamicAmount
                      }
                      placeholder={global.translate('Amount')}
                    />
                  </div>
                </div>
              ) : null}
              {inputField?.Meta?.amountType === 'qrcode' ? (
                <div className="card-box__field">
                  <span className="card-title">
                    {global.translate('Amount')}
                  </span>
                  <div className="operator-input">
                    <Input
                      name="amount"
                      label={{
                        basic: true,
                        content: currentWalletOption?.CurrencyCode,
                      }}
                      maxLength={0}
                      labelPosition="right"
                      value={scanedAmount}
                      onChange={e => e.preventDefault()}
                      placeholder={global.translate('Amount')}
                    >
                      <div
                        style={{
                          display: 'flex',
                          position: 'absolute',
                          right: '6px',
                          top: '7px',
                          alignItems: 'center',
                        }}
                      >
                        <Label
                          style={{
                            background: 'transparent',
                            fontSize: '1em',
                          }}
                        >
                          {' '}
                          {currentWalletOption?.CurrencyCode}
                        </Label>
                        <Icon
                          onClick={() => setScanAmount(true)}
                          size="large"
                          style={{
                            marginTop: '1px',
                            marginRight: '10px',
                            cursor: 'pointer',
                          }}
                          name="qrcode"
                        />
                      </div>
                      <input />
                    </Input>
                  </div>
                </div>
              ) : null}
            </>
          ) : null}
          {scanAmount && !scanedAmount && (
            <div>
              <QrReader
                delay={300}
                onError={handleError}
                onScan={handleScanAmount}
                style={{ width: '100%' }}
              />
            </div>
          )}
          {nextStep === true ? (
            <>
              {gatewayTransactionConfirmation &&
                gatewayTransactionConfirmation?.data?.map(item => {
                  return (
                    <>
                      <div className="item-decider">
                        <h3
                          className="page-title"
                          style={{ fontWeight: '700' }}
                        >
                          {global.translate('Summary')}
                        </h3>
                        <div className="item-decider__box">
                          <span className="left">
                            {global.translate('Amount to be sent')}
                          </span>
                          <span className="right">
                            {item?.AmountToBeSent}
                          </span>
                        </div>
                        <div className="item-decider__box">
                          <span className="left">
                            {global.translate('Fees')}
                          </span>
                          <span className="right">{item?.Fees}</span>
                        </div>
                        <div className="item-decider__box">
                          <span className="left">
                            {global.translate('Exchange fees')}
                          </span>
                          <span className="right">
                            {item?.ExchangeFees}
                          </span>
                        </div>
                        <div className="item-decider__box">
                          <span className="left">
                            {global.translate('Exchange rate')}
                          </span>
                          <span className="right">
                            {item?.ExchangeRate}
                          </span>
                        </div>
                        <div className="item-decider__box">
                          <span className="left">
                            {global.translate('Taxes')}
                          </span>
                          <span className="right">{item?.Taxes}</span>
                        </div>
                        <div className="item-decider__box">
                          <span className="left">
                            {global.translate('Total amount')}
                          </span>
                          <span className="right">
                            {item?.TotalAmount}
                          </span>
                        </div>
                        <div style={{ marginTop: '30px' }}>
                          <span style={{ fontWeight: '600' }}>
                            PIN:
                          </span>
                          <PinCodeForm
                            label={global.translate(
                              'Enter PIN to confirm',
                            )}
                            onChange={e =>
                              onChange({ ...e, input: 'pin' })
                            }
                          />
                        </div>
                      </div>
                    </>
                  );
                })}
              {!gatewayTransactionConfirmation?.data?.length ? (
                <>
                  {' '}
                  <div style={{ width: '100%' }}>
                    <Image
                      src={loadTransactions}
                      style={{ width: '100%' }}
                    />
                  </div>
                </>
              ) : null}
            </>
          ) : null}
          {nextStep === false ? (
            <div className="bottom-transition__box">
              <div className="select-box">
                <span></span>
              </div>
              <div className="head-transition-btn">
                <Button
                  className="process-btn"
                  disabled={alertMsg}
                  onClick={
                    inputField?.Meta !== undefined &&
                    inputField?.Meta?.verifyAccount === true
                      ? onVerifyGatewayAccount
                      : onGatewayTransactionConfirmation
                  }
                  loading={
                    gatewayTransactionConfirmation?.loading ||
                    verifiedGatewayAccount?.loading
                  }
                >
                  {global.translate('Proceed')}
                </Button>
              </div>
            </div>
          ) : null}
          {nextStep === true ? (
            <div className="bottom-transition__box">
              {gatewayTransactionConfirmation?.data?.length ? (
                <>
                  <Button id="btn-back" onClick={() => backBtn()}>
                    {global.translate('Back')}
                  </Button>
                  <Button
                    className="process-btn"
                    loading={sendFundsToGateway?.loading}
                    onClick={() => onSubmit()}
                  >
                    {global.translate('Pay')}
                  </Button>
                </>
              ) : null}
            </div>
          ) : null}
        </ContentBox>
      ) : null}

      <CustomModal size={size} open={open}>
        <div className="custom-modal__style">
          <span>{global.translate('Upload document')}</span>
        </div>
        <div className="custom-modal__style-bottom">
          <div className="form-box">
            {file && (
              <>
                {file?.type === 'pdf' ? (
                  <div className="img-upload">
                    <BsFilePdfFill size={100} className="img-icon" />
                  </div>
                ) : (
                  <div className="img-upload">
                    <img src={file?.file} alt="" />
                  </div>
                )}
              </>
            )}
            <div className="style-bottom img-upload">
              <Button
                className="back-btn"
                onClick={() => {
                  dispatchPayBills({ type: 'close' });
                  setFile({ file: '', fileType: '' });
                }}
              >
                {global.translate('Close')}
              </Button>
              <Button
                className="save-btn"
                onClick={() => {
                  uploadImageTemp();
                }}
              >
                {global.translate('Save')}
              </Button>
            </div>
          </div>
        </div>
      </CustomModal>

      <CustomModal size={size} open={openResult2Modal}>
        <div className="custom-modal__style">
          <span>{global.translate(responseData?.Description)}</span>
          <div className="custom-modal__style-bottom">
            <div className="form-box">
              <div className="div__separator"></div>
              <div className="form-box__items">
                <span style={{ color: '#262D30' }}>
                  {global.translate('Total amount')}
                </span>
                <span style={{ color: '#262D30', fontWeight: '600' }}>
                  {responseData?.TotalAmount}
                </span>
              </div>

              {responseData?.Voucher &&
                responseData?.Voucher !== undefined && (
                  <>
                    <div className="div__separator"></div>
                    <div className="form-box__items">
                      <span style={{ color: '#262D30' }}>
                        {global.translate('Transfer number')}
                      </span>
                      <span
                        style={{
                          color: '#262D30',
                          fontWeight: '600',
                        }}
                      >
                        {responseData?.Voucher?.TransferNumber}
                      </span>
                    </div>

                    <div className="div__separator"></div>
                    <div className="form-box__items">
                      <span style={{ color: '#262D30' }}>
                        {global.translate('Security code')}
                      </span>
                      <span
                        style={{
                          color: '#262D30',
                          fontWeight: '600',
                        }}
                      >
                        {responseData?.Voucher?.SecurityCode}
                      </span>
                    </div>
                  </>
                )}

              {partnerResponse?.values &&
                partnerResponse?.values?.map(response => (
                  <>
                    <div className="div__separator"></div>
                    <div className="form-box__items">
                      <span style={{ color: '#262D30' }}>
                        {global.translate(response?.text)}
                      </span>
                      <span
                        style={{
                          color: '#262D30',
                          fontWeight: '600',
                        }}
                      >
                        {response?.value}
                      </span>
                    </div>
                  </>
                ))}
            </div>
          </div>

          <div style={{ marginTop: '20px' }}>
            <Button
              style={{
                color: ' #D0342F',
                border: 'none',
                background: 'none',
                width: '100px',
                float: 'left',
                textAlign: 'left',
                padding: '0px',
              }}
              onClick={() => {
                history.push('/pay-bills');
                dispatchPayBills({ type: 'closeResult2Modal' });
                clearGatewayTransferConfirmation()(dispatch);
                clearSendFundToGatewayStore()(dispatch);
              }}
            >
              {global.translate('Close')}
            </Button>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default BillsCategories;
